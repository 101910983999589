import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  ErrorMessageResources,
  ErrorTitleResources,
  MappingRequestResources,
  PointTransferHisotyResources,
  PointTransferResources,
} from "./intl";

const resources = {
  en: {
    translation: {
      DEFAULT__TITLE: "Loading",
      LANGUAGES: "Language",
      LOGIN: "Log in",
      LOGIN_HERE_CREW: "Log in here",
      LOGIN_HERE_DRIVER: "Log in here",
      LOGOUT: "Log out",
      QUIT_GO_CREW_PORTAL: "Quit GO Crew Portal",
      QUIT_GO_DRIVER_PORTAL: "Quit GO Driver Portal",
      EXECUTE_LOGOUT: "Log out",
      ASK_LOGOUT: "Are you sure you want to log out?",
      CANCEL: "Cancel",
      EDIT: "Edit",
      SETTINGS: "Settings",
      WELCOME_BACK: "Welcome back",
      CONTACT: "Contact",
      MAKE_CONTACT: "Make contact",
      INTERNAL_SERVER_ERROR: "An error occurred",
      INTERNAL_SERVER_ERROR_EXAMPLE: "Please try again later.",
      ERROR_OCCURRED: "An error occurred while processing your request.",
      UNAUTHORIZED: "Session expired",
      UNAUTHORIZED_EXAMPLE: "Please log in again.",
      PAGE_NOT_FOUND: "Page not found",
      PAGE_MIGHT_REMOVED: "The page you are looking for might have been removed.",
      SERVICE_UNAVAILABLE: "Under maintenance",
      SERVICE_UNAVAILABLE_EXAMPLE: "Please try again later.",
      RETURN_TO_LOGIN: "Return to login",
      CREW_PORTAL: "Crew Portal",
      DEFAULT_CREW_TITLE: "GO Crew Portal",
      DRIVER_PORTAL: "Driver Portal",
      DEFAULT_DRIVER_TITLE: "GO Driver Portal",
      SEND: "Send",
      TOP: "TOP",
      TO_TOP: "To TOP",
      POINT: "Point",
      NOTIFICATION: "Notice",
      ACCOUNT_REGISTRATION: "Account Registration",
      TO_ACCOUNT_REGISTRATION: "Back to Account Registration",
      ACCOUNT_REGISTRATION_HERE: "Account registration here",
      WHAT_IS_ACCOUNT_REGISTRATION: "Click here for account registration",
      WHAT_IS_PHONE_NUMBER_CHANGE: "How do I change my phone number?",
      MY_PAGE: "My Page",
      SEE_MY_PAGE: "See My Page",
      TO_MY_PAGE: "Back to My Page",
      ACCOUNT: "Account",
      CREW_NUMBER: "Crew Number",
      DRIVER_NUMBER: "Driver Number",
      ERROR: "Error",
      ERROR_FEE: "Error Fee",
      POINT_EXCHANGE: "Point Exchange",
      POINT_EXCHANGE_OF_GO_CREW: "Point Exchange of GO Crew",
      POINT_EXCHANGE_OF_GO_DRIVER: "Point Exchange of GO Driver",
      OWNED_POINT: "Owned Point",
      POINT_EXPIRE: "You have % points that will expire in % days!",
      SEE_EXPIRE_POINT: "See expiring points",
      EXCHANGE_POINT: "Exchange points",
      ONE_TIME_REMITTANCE_FEE_IS_WAIVED: "One time remittance fee waived (once every six months)",
      POINTS_EXPIRING_SOON: "Points expiring soon",
      CHECK_APPLICATION_STATUS: "Check application status",
      EARNED_POINT: "Earned Point",
      TIP: "Tip",
      EARN: "Earned",
      EXPIRE: "Expired",
      APPLY: "Applied",
      RETURN: "Returned",
      SEE_POINT_HISTORY: "See point history",
      SEE_MORE_NOTIFICATION: "See more notices",
      BACK_TO_LIST: "Back to list",
      SUPPORT: "Support",
      PRIVACY_POLICY: "Privacy Policy",
      TERMS_OF_CREW_USE: "GO Crew Point Terms of Use",
      TERMS_OF_DRIVER_USE: "Terms of Use",
      MANUAL: "Manuals",
      CUSTOMER_NAME: "Dear %",
      UNREAD: "Unread",
      POINT_EXPIRE_TO: "Points expire in",
      AFTER_MONTH: "Within %1~%2 months, points will expire",
      AFTER_MONTH_2: "Within %1~%2 months, points will expire",
      AFTER_DAY: "Within % days, points will expire",
      AFTER_DAY_2: "Within % days",
      UNTIL_DATE: "Expire on %",
      PLEASE_AUTHENTICATE: "Please authenticate to register your account",
      PLEASE_INPUT: "Please input the following items to authenticate.",
      PHONE_NUMBER: "Phone number",
      PHONE_NUMBER_EXAMPLE: "090 1234 5678",
      PHONE_NUMBER_REQUIRED: "Phone number is required",
      PHONE_NUMBER_VALID: "Must be a valid phone number",
      PHONE_NUMBER_NOTE: "Please input without hyphens (-)",
      PIN_CODE: "Authentication code",
      PIN_CODE_NOTE: "The authentication code is a 4-digit number sent via SMS from the driver app.",
      PIN_CODE_VALID: "Must be a valid authentication code",
      PIN_CODE_REQUIRED: "Authentication code is required",
      CONFIRM_CODE_VALID: "Must be a valid confirmation code",
      CONFIRM_CODE_REQUIRED: "Confirmation code is required",
      AUTHENTICATE: "Authenticate",
      ALREADY_HAVE_ACCOUNT: "Already have an account?",
      PLEASE_INPUT_EMAIL: "Please input your email address",
      PLEASE_INPUT_EMAIL_EXAMPLE:
        "Please input the email address you used to register for GO Driver Portal. We will send a confirmation email.",
      EMAIL: "Email address",
      EMAIL_REQUIRED: "Email address is required",
      EMAIL_INVALID: "Must be a valid email",
      EMAIL_TOO_LONG: "Please enter up to 254 characters.",
      CREW_NUMBER_VALID: "Must be a valid crew number",
      CREW_NUMBER_REQUIRED: "Crew number is required",
      CREW_NUMBER_NOTE: "Please input the number you entered when you opened the station in the driver app.",
      AUTH_MAIL: "Email address input",
      CHANGE_EMAIL: "Change email address",
      CHANGE_PHONE_NUMBER: "Change phone number",
      CHANGE_PASSWORD: "Change password",
      EMAIL_SENT:
        "We have sent a confirmation email to your email address. Please check the email for further instructions.",
      EMAIL_SUCCESS: "Your email address has been changed",
      PHONE_NUMBER_SUCCESS: "Your phone number has been changed",
      PASSWORD_SUCCESS: "Your password has been changed",
      REGISTRATION_SUCCESS: "Your GO Driver Portal account has been registered.",
      FEATURE_PHONE: "If you are using a feature phone (not a smartphone or computer), please press Next.",
      NEXT: "Next",
      PLEASE_INPUT_CONFIRM_CODE_BY_SMS: "Please input the confirmation code you received via SMS.",
      CONFIRM_CODE_SENT_BY_SMS:
        "We have sent the verification code to your mobile phone number via SMS (message).\nPlease enter the 4-digit verification code within 30 minutes.",
      PLEASE_INPUT_CONFIRM_CODE_BY_EMAIL: "Please input the confirmation code you received via email.",
      CONFIRM_CODE_SENT_BY_EMAIL:
        "We have sent a confirmation code to your email. Please input the 4-digit code within 30 minutes.",
      CONFIRM_CODE: "Confirmation code",
      CONFIRM_CODE_NOT_SENT:
        "If you did not receive the confirmation code or if the confirmation failed, please request a new confirmation code.",
      WHO_DO_NOT_RECEIVE_CONFIRM_CODE: "Click here if you did not receive it",
      WHO_DO_NOT_RECEIVE_CONFIRM_CODE_OR_CHALLENGE_RESENDING:
        "Click here if you did not receive the code or challenge resending",
      WHAT_IS_CONFIRM_CODE: "What is a confirmation code?",
      TRY_AGAIN: "Try again",
      CONTACT_US: "If this screen appears repeatedly, please contact us using the following information.",
      CONTACT_US_WEB: "Contact us via web",
      PLEASE_INPUT_PASSWORD: "Please register your password",
      PASSWORD: "Password",
      PASSWORD_REQUIRED: "Password is required",
      PASSWORD_TOO_SHORT: "Please enter at least 8 characters.",
      PASSWORD_TOO_LONG: "Please enter up to 64 characters.",
      PASSWORD_CONFIRM_VALID: "Passwords must match",
      PASSWORD_CONFIRM_REQUIRED: "Password confirmation is required",
      PASSWORD_EXAMPLE: "Please set a password with at least 8 characters, including letters and numbers.",
      PASSWORD_CONFIRM: "Password (confirm)",
      SHOW_PASSWORD: "Show password",
      REGISTER: "Register",
      SET: "Set",
      REGISTER_ACCOUNT: "Register account",
      WHO_DO_NOT_HAVE_ACCOUNT: "Click here if you do not have an account",
      ACCOUNT_REGISTERED: "Your GO Driver Portal account has been registered.",
      ACCOUNT_REGISTERED_EXAMPLE: "Please check your email for a confirmation email.",
      BOOKMARK_THIS_PAGE: "We recommend bookmarking this page.",
      AUTHENTICATION_FAILED: "Authentication failed",
      AUTHENTICATION_FAILED_EXAMPLE: "Please check the authentication code and try again.",
      AUTHENTICATION_CODE_INVALIDATED: "The authentication code has been invalidated.",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE:
        "The authentication code has been invalidated due to too many failed attempts.",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE2:
        "A new authentication code can be issued 24 hours after the authentication code is issued.",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE3: "Please try again after some time.",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE4:
        "For information on how to reissue an authentication code, please refer to the FAQ.",
      FAQ: "FAQ",
      AUTHENTICATION_CODE_EXPIRED: "The authentication code has expired",
      AUTHENTICATION_CODE_EXPIRED_EXAMPLE: "Please send the authentication code again from the driver app.",
      EMAIL_ALREADY_REGISTERED: "This email address is already registered with the GO Driver Portal.",
      EMAIL_ALREADY_REGISTERED_EXAMPLE: "Please check your input and try again.",
      CONFIRM_FAILED: "Confirmation failed",
      CONFIRM_FAILED_EXAMPLE: "Please check the confirmation code and try again.",
      CONFIRM_CODE_EXPIRED: "The confirmation code has expired",
      CONFIRM_CODE_EXPIRED_EXAMPLE: "Please send the confirmation code again.",
      ACCOUNT_REGISTRATION_FAILED: "Account registration failed",
      ACCOUNT_REGISTRATION_FAILED_EXAMPLE: "Please contact us using the information below.",
      FORGOT_PASSWORD: "Forgot your password?",
      RESET_PASSWORD: "Reset your password",
      TO_RESET_PASSWORD: "Back to Reset password",
      PASSWORD_NOTE:
        "Please enter a password that contains both half-width letters and numbers, and is at least 8 characters long.",
      PASSWORD_CONFIRM_NOTE: "Please enter the same password as above.",
      WHO_CHANGED_PHONE_NUMBER: "Click here if you changed your phone number",
      DO_CHANGE_EMAIL: "Change email address",
      WHO_CHANGED_EMAIL: "Click here if you changed your email address",
      CANNOT_LOGIN: "Cannot log in?",
      PASSWORD_INVALID: "Please enter half-width alphanumeric characters or symbols.",
      PASSWORD_INVALID_MIXED: "Please enter a combination of letters and numbers.",
      PHONE_OR_PASSWORD_INVALID: "The phone number or password is incorrect.",
      CONFIRM_CODE_MAX: "The confirmation code is too long",
      CONFIRM_CODE_MIN: "The confirmation code is too short",
      ACCOUNT_LOCKED: "The account has been locked",
      ACCOUNT_LOCKED_EXAMPLE_1: "The account has been locked due to too many failed login attempts.",
      ACCOUNT_LOCKED_EXAMPLE_2: "Please reset your password to unlock the account.",
      WHAT_IS_ACCOUNT_LOCKED: "What should I do if my account is locked?",
      RESET_PASSWORD_EXAMPLE: "Reset your password",
      PLEASE_INPUT_EXAMPLE: "Please input the following items.",
      PLEASE_INPUT_EXAMPLE_AUTH: "Please input the following items to proceed with the authentication.",
      PLEASE_SEND_PIN_CODE: "Please send an authentication code for identity verification",
      EMAIL_SENT_FOR_AUTHENTICATION: "We have sent an authentication code to your email address.",
      EMAIL_SENT_FOR_CONFIRMATION: "We have sent a confirmation code to your email address.",
      PLEASE_SEND_PIN_CODE_BY_EMAIL: "We will send an authentication code to your registered email address.",
      PLEASE_CHECK_YOUR_EMAIL: "Please check your email and follow the instructions in the email.",
      SMS_SENT_FOR_AUTHENTICATION: "We have sent an authentication code to your mobile phone number.",
      PLEASE_SEND_PIN_CODE_BY_SMS:
        "We will send an authentication code to your registered mobile phone number via SMS.",
      PLEASE_CHECK_YOUR_SMS: "Please check your SMS and follow the instructions in the SMS.",
      SEND_PIN_CODE: "Send authentication code",
      PLEASE_INPUT_PIN_CODE: "Please input the authentication code you received via email",
      PIN_CODE_SENT_EXAMPLE:
        "We have sent an authentication code to your email. Please input the 4-digit code within 30 minutes.",
      PIN_CODE_NOT_SENT_EXAMPLE:
        "If you did not receive the authentication code, please send the authentication code again.",
      SEND_PIN_CODE_AGAIN: "Send authentication code again",
      WHO_DO_NOT_RECEIVE_PIN_CODE: "Click here if you did not receive the code",
      WHAT_IS_PIN_CODE: "What is an authentication code?",
      WHO_DO_NOT_RECEIVE_PIN_CODE_OR_CHALLENGE_RESENDING:
        "Click here if you did not receive the code or challenge resending",
      PLEASE_INPUT_NEW_EMAIL: "Please input your new email address",
      SEND_CONFIRM_MAIL: "Send confirmation email",
      PLEASE_INPUT_NEW_PHONE_NUMBER: "Please input your new phone number",
      SEND_CONFIRM_SMS: "Send confirmation SMS",
      PLEASE_REGISTER_NEW_PASSWORD: "Please register a new password",
      NEW_PASSWORD: "New password",
      NEW_PASSWORD_CONFIRM: "New password (confirm)",
      PASSWORD_RESET_SUCCESS: "Your password has been reset",
      PASSWORD_RESET_FAILED: "Failed to reset your password",
      PASSWORD_RESET_FAILED_EXAMPLE: "Please contact us using the information below.",
      PIN_CODE_INVALIDATED: "The authentication code has been invalidated",
      PIN_CODE_INVALIDATED_EXAMPLE_1: "The authentication code has been invalidated due to too many failed attempts.",
      PIN_CODE_INVALIDATED_EXAMPLE_2: "In addition, issuing a new authentication code has also been locked.",
      PIN_CODE_INVALIDATED_EXAMPLE_3: "Please try again after some time.",
      POINT_EXCHANGE_APPLICATION: "Point exchange application",
      POINT_EXCHANGE_APPLICATION_HISTORY: "Point exchange application history",
      POINT_EARN_HISTORY: "Point earn history",
      POINT_EXCHANGE_APPLICATION_HISTORY_EXAMPLE:
        "Please check the application status from the point exchange application history.",
      SEE_APPLICATION_HISTORY: "See this application history",
      POINT_EXCHANGE_APPLICATION_FAILED_1: "Failed to apply for point exchange",
      POINT_EXCHANGE_APPLICATION_FAILED_2: "Please start over from the beginning.",
      BACK_TO_TOP: "Back to top",
      RECEIVE_METHOD: "Receive method",
      BANK_TRANSFER: "Bank transfer",
      EXCHANGE_TO_CASH: "Exchange to cash",
      BANK_TRANSFER_EXAMPLE_1: "It takes 1-5 business days to transfer to a bank account.",
      BANK_TRANSFER_SCHEDULE: "Bank transfer schedule",
      REMITTANCE_FEE: "Remittance fee",
      ONCE_FREE: "Once free",
      EXCHANGEABLE_POINT: "Exchangeable point",
      POINT_TO_YEN: "*1P=1 yen",
      POINT_EXPIRE_IN: "Points that will expire in % days",
      EXCHANGE_AMOUNT: "Exchange amount",
      PLEASE_INPUT_EXCHANGEABLE_POINT: "Please input the amount of exchangeable points",
      YEN: "yen",
      CONFIRM_APPLICATION_CONTENTS: "Confirm application contents",
      TOTAL_CONSUMPTION_POINT: "Total consumption point",
      BREAKDOWN: "Breakdown",
      OTHER: "Other",
      BANK_ACCOUNT: "Bank Account",
      BANK: "Bank",
      BRANCH_NAME: "Branch Name",
      ACCOUNT_TYPE: "Account Type",
      ACCOUNT_NUMBER: "Account Number",
      ACCOUNT_NAME: "Account Name",
      BANK_ACCOUNT_EXAMPLE_1:
        "Please register the transfer destination account information on the GMO-PG remittance service site.",
      BANK_ACCOUNT_EXAMPLE_2:
        "After applying, a <strong>user number</strong> will be sent to your mobile phone number via SMS.",
      BANK_ACCOUNT_EXAMPLE_3: "The user number is required for authentication of account information.",
      APPLY_WITH_THIS_CONTENTS: "Apply with this contents",
      USER_NUMBER_SENT_TO_SNS: "User number will be sent to SNS (message)",
      BACK_AND_CHANGE_CONTENTS: "Go back and change contents",
      APPLICATION_NOT_COMPLETED_YET: "Application is not yet complete",
      REGISTER_BANK_ACCOUNT: "Register bank account",
      MOVE_TO_GMO_PG: "Move to GMO-PG remittance service site",
      USER_NUMBER_NOT_SENT:
        "If you do not receive your user number, please resend your user number using the button below.",
      SEND_USER_NUMBER_AGAIN: "Resend user number",
      CANCEL_APPLICATION: "Cancel application",
      POINT_EXCHANGE_APPLICATION_ACCEPTED: "Point exchange application accepted",
      APPLICATION_ID: "Application ID",
      BANK_TRANSFER_EXAMPLE_2:
        "For bank transfers, it takes 1-3 business days for the money to be transferred to your bank account.",
      BANK_TRANSFER_EXAMPLE_3:
        "Please register the transfer destination account information on the GMO-PG remittance service site before applying for point exchange.",
      POINT_HISTORY: "Point Earning History",
      POINT_TRANSFER_SUMMARY: "Annual Point Transfer Information",
      TRANSFER_AMOUNT: "Transfer Amount",
      TRANSFER_FEE: "Transfer Fee",
      TEN_PERCENT_TAX_RATE: "10% Applicable Tax",
      FEE_BREAKDOWN: "Fee Breakdown",
      FEE_WITH_TAX: "Transfer fee and error transfer include 10% consumption tax.",
      TOTAL: "Total",
      FEE_TOTAL: "Total Fee",
      ANNUAL_TRANSFER_INFO: "About Annual Point Transfer Information",
      PRIORITY_PASS: "Priority pass",
      CANCEL_COMPENSATION: "Cancel compensation",
      AI_RESERVATION: "AI reservation",
      PEAK_TIME: "Peak time",
      EXPIRED: "Expired",
      POINT_RETURN: "Point return",
      PLEASE_INPUT_PIN_CODE_FROM_SMS: "Please enter the authentication code received by SMS",
      PIN_CODE_SENT_TO_SMS_EXMAPLE_1: "The authentication code has been sent to your mobile phone number via SMS.",
      PIN_CODE_SENT_TO_SMS_EXMAPLE_2: "Please enter the 4-digit authentication code within 30 minutes.",
      SKIP_PIN_CODE_INPUT_NEXT_TIME: "Skip authentication code input next time",
      PIN_CODE_NOT_SENT_EXAMPLE_2:
        "If you do not receive the authentication code, please resend the authentication code using the button below.",
      POINT_EXCHANGE_APPLICATION_ERROR: "Point exchange application error",
      PIN_CODE_EXPIRED: "The authentication code has expired",
      PIN_CODE_EXPIRED_EXAMPLE: "Please start over.",
      START_OVER: "Start over",
      TO_POINT_EXCHANGE_APPLICATION: "To point exchange application",
      POINT_EXCHANGE_APPLICATION_HISTORY_LIST: "Point exchange application history list",
      REMITTANCE_PROCESSING: "Processing",
      REMITTANCE_COMPLETED: "Completed",
      REMITTANCE_ERROR: "Error",
      REMITTANCE_CANCEL: "Cancel",
      POINT_RETURN_EXAMPLE_1: "Due to an error, the points returned after deducting the error fee (bank transfer) % P.",
      RETURNED_POINT: "Returned point",
      RETURNED_DATE: "Returned date",
      POINT_RETURN_EXAMPLE_2: "Points that have expired at the time of return cannot be used after the return.",
      POINT_RETURN_EXAMPLE_3: "The points will be returned after deducting the error fee (bank transfer).",
      ERROR_HANDLING_FEE: "About error handling fee",
      PERIOD: "Period",
      FROM: "From",
      TO: "To %",
      YEAR: "Year",
      MONTH: "Month",
      APPLICATION_STATUS: "Application status",
      ALL_APPLICATIONS: "All applications",
      FILTER: "Filter",
      STATUS: "Status",
      ERROR_DETAIL: "Error detail",
      ACCOUNT_NUMBER_DIFFERENCE: "Account number difference",
      PEAK_TIME_RETURN: "Peak time return",
      CLOSE: "Close",
      WHO_DO_NOT_RECEIVE_SMS: "SMS (message) not delivered.",
      WHO_DO_NOT_RECEIVE_EMAIL: "Email not delivered.",
      VALID_HALF_NUM: "Please enter only half-width numbers.",
      VALID_NUM_LENGTH: "Please enter a phone number with 11 digits (excluding hyphens).",
      VALID_TOO_LONG: "Please enter within % characters.",
      VALID_PHONE_NUMBER_AREA_CODE: "Please enter a phone number starting with 070, 080, or 090.",
      REASON: "Reason",
      CONFIRM_BEFORE_CONTACT: "Please read the following items before contacting us.",
      CONFIRM_BEFORE_CONTACT_1: "Please also check the <Link>frequently asked questions</Link> before contacting us.",
      CONFIRM_BEFORE_CONTACT_2: "Please fill in all items and submit the contact content.",
      CONFIRM_BEFORE_CREW_CONTACT:
        "For account information, please make sure to contact us from the crew member himself.",
      CONFIRM_BEFORE_DRIVER_CONTACT: "For account information, please make sure to contact us from the driver himself.",
      _REQUIRED: "is required",
      CONTACT_FORM_NOTICE: "Check if the office has been integrated",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_0:
        "If your affiliated office has changed due to changes or integration of offices, the following procedures are required.",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_1: "If you have already registered for the GO Crew Portal*",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_2: "Register for the GO Crew Portal with your new crew number",
      CONTACT_ACCOUNT_FORM_NOTICE_MESSAGE: "For *1, please select the inquiry type 'Account reset' and request it.",
      CONTACT_FORM_NOTICE_MESSAGE:
        "For *1, please log in to the existing GO Crew Portal and select the inquiry type 'Account reset' from the Inquiry Form to make a request.",
      CONTACT_TYPE: "Contact type",
      ACCOUNT_RESET_REQUEST: "Account reset request",
      TELEPHONE_NUMBER: "Telephone number",
      TELEPHONE_NUMBER_NOTE: "Please enter without hyphens (-)",
      NAME: "Name",
      COMPANY_NAME: "Company name",
      COMPANY_NAME_NOTE: "If you are an individual taxi driver, please enter the name of your association.",
      OFFICE_NAME: "Office name",
      CONTACT_CONTENTS: "Contact contents",
      CONTACT_CREW_NUMBER_NOTE:
        "Please enter the number used for opening/logging in on the crew terminal\nIf you are not a crew member, please enter 0000",
      CONTACT_DRIVER_NUMBER_NOTE: "If you are not a GO driver, please enter 0000",
      CONTACT_NOTICE_CREW_1:
        "We are unable to respond to inquiries that are not related to GO Crew Points. Thank you for your understanding.",
      CONTACT_NOTICE_DRIVER_1:
        "We are unable to respond to inquiries that are not related to GO Driver Points. Thank you for your understanding.",
      CONTACT_NOTICE_2: "If you have set up spam filters, you may not receive a response from our support desk.",
      CONTACT_NOTICE_3:
        "If you do not receive a response from our support desk, please configure your email settings to accept emails from our domain '@goinc.jp' and contact us again. For detailed instructions, please refer to your carrier's support.",
      CONTACT_NOTICE_4:
        "The content sent will be checked by GO Inc., the operating company, for service improvement. Also, depending on the content, it may be disclosed to taxi operators.",
      CONTACT_PRIVACY_POLICY_AGREE: "Agree to the <Link>Privacy Policy</Link>",
      CONTACT_NOTICE_5: "<Link>Note on account reset</Link>",
      CONTACT_RECEIVED: "Your inquiry has been received",
      CONTACT_RESPONSE_TIME_NOTICE:
        "Our response will be sent by email from the person in charge within 1-2 business days as a rule.",
      UNFILLED_FIELDS_NOTICE: "There are fields that have not been filled in",
      PRIVACY_POLICY_UNAGREED: "Privacy policy has not been agreed to.",
      CONTACT_SUPPORT_HOURS_NOTICE:
        "Support hours: Weekdays 9:00-17:00 (excluding Saturdays, Sundays, holidays, and New Year holidays) \n* For those who contacted us outside the support hours, we will reply in order from 9:00 on the next support day.",
      REQUIRES_OPERATION_WITH_DRIVER_APP: "Please operate with the driver app",
      OPEN_THE_DRIVER_APP_FROM_YOUR_SMART_PHONE: "Open the driver app from your smartphone",
      LOGIN_AS_DRIVER_NOTICE: "Please log in with the same account as the driver application.",
      CHECK_EMAIL_NOTICE: "Please check the email address notes",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_0: "The following email addresses cannot be used in the GO Crew Portal.",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_1: "Using a special format email address (RFC violation address)*",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_2: "Receiving rejection settings (junk mail settings)",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_3: "Please check the following frequently asked questions for details.",
      WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED: "What should I do if the confirmation email does not arrive?",
      ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL: "I have not received any emails from the GO Crew Portal.",
      IF_YOU_DO_NOT_RECEIVE_CONFIRMATION_EMAIL: "If you do not receive the confirmation email",
      CHECK_BELOW_FAQ:
        "You may have domain rejection settings (junk mail settings). Please check the following frequently asked questions for details.",
      CONFIRMATION_EMAIL_NOT_ARRIVED: "Confirmation email not arrived",
      DRIVER_SCORE: "Driver score",
      WHAT_IS_DRIVER_SCORE: "What is a driver score?",
      MAINTENANCE_PERIOD: "Scheduled Maintenance Period",
      MAINTENANCE_PERIOD_SUFFIX: "approx.",
      WHAT_IS_COOKIE_DISABLED: "What should I do if cookies are disabled?",
    },
  },
  jp: {
    translation: {
      DEFAULT__TITLE: "読み込み中",
      LANGUAGES: "言語",
      LOGIN: "ログイン",
      LOGIN_HERE_CREW: "ログインの方はこちら",
      LOGIN_HERE_DRIVER: "ログインはこちら",
      LOGOUT: "ログアウト",
      QUIT_GO_CREW_PORTAL: "GO乗務員ポータルを終了する",
      QUIT_GO_DRIVER_PORTAL: "GOドライバーポータルを終了する",
      EXECUTE_LOGOUT: "ログアウトする",
      ASK_LOGOUT: "ログアウトをしますか？",
      CANCEL: "キャンセル",
      EDIT: "変更",
      SETTINGS: "設定",
      WELCOME_BACK: "ようこそ",
      CONTACT: "お問い合わせ",
      MAKE_CONTACT: "問い合わせる",
      INTERNAL_SERVER_ERROR: "エラーが発生しました",
      INTERNAL_SERVER_ERROR_EXAMPLE: "申し訳ありませんが、しばらく経ってからやり直してください。",
      UNAUTHORIZED: "セッションが切れました",
      UNAUTHORIZED_EXAMPLE: "お手数ですが再度ログインをお願いします。",
      PAGE_NOT_FOUND: "指定されたURLのページが見つかりません",
      PAGE_MIGHT_REMOVED: "お探しのページは削除された可能性があります。",
      SERVICE_UNAVAILABLE: "メンテナンス中",
      SERVICE_UNAVAILABLE_EXAMPLE: "申し訳ありませんが、しばらく経ってからやり直してください。",
      RETURN_TO_LOGIN: "ログイン画面に戻る",
      CREW_PORTAL: "乗務員ポータル",
      DEFAULT_CREW_TITLE: "GO乗務員ポータル",
      DRIVER_PORTAL: "ドライバーポータル",
      DEFAULT_DRIVER_TITLE: "GOドライバーポータル",
      SEND: "送信する",
      TOP: "TOP",
      TO_TOP: "TOPへ",
      POINT: "ポイント",
      NOTIFICATION: "お知らせ",
      MY_PAGE: "マイページ",
      ACCOUNT_REGISTRATION: "アカウント登録",
      TO_ACCOUNT_REGISTRATION: "アカウント登録へ",
      ACCOUNT_REGISTRATION_HERE: "アカウント登録の方はこちら",
      WHAT_IS_ACCOUNT_REGISTRATION: "アカウント登録方法について",
      WHAT_IS_PHONE_NUMBER_CHANGE: "携帯電話番号が変更できません",
      WHAT_IS_MAIL_ADDRESS_CHANGE: "メールアドレスが変更できません",
      SEE_MY_PAGE: "マイページを見る",
      TO_MY_PAGE: "マイページへ",
      ACCOUNT: "アカウント",
      CREW_NUMBER: "乗務員番号",
      DRIVER_NUMBER: "乗務員番号",
      ERROR: "エラー",
      ERROR_FEE: "エラー手数料",
      POINT_EXCHANGE: "ポイント交換",
      POINT_EXCHANGE_OF_GO_CREW: "GO乗務員ポイント交換",
      POINT_EXCHANGE_OF_GO_DRIVER: "GOドライバーポイント交換",
      OWNED_POINT: "保有ポイント",
      POINT_EXPIRE: "% 日以内で期限が切れるポイントが【 % 】ポイントあります！",
      SEE_EXPIRE_POINT: "ポイントの有効期限を確認する",
      EXCHANGE_POINT: "ポイントを交換する",
      ONE_TIME_REMITTANCE_FEE_IS_WAIVED: "送金手数料1回分無料(半年に1回）",
      POINTS_EXPIRING_SOON: "有効期限の近いポイント",
      CHECK_APPLICATION_STATUS: "申請状況を確認する",
      EARNED_POINT: "獲得ポイント",
      TIP: "チップ",
      EARN: "獲得",
      EXPIRE: "失効",
      APPLY: "申請",
      RETURN: "返却",
      SEE_POINT_HISTORY: "ポイント実績を見る",
      SEE_MORE_NOTIFICATION: "お知らせをもっと見る",
      BACK_TO_LIST: "一覧に戻る",
      SUPPORT: "サポート",
      PRIVACY_POLICY: "プライバシーポリシー",
      TERMS_OF_CREW_USE: "GO乗務員ポイント規約",
      TERMS_OF_DRIVER_USE: "利用規約",
      MANUAL: "マニュアル",
      CUSTOMER_NAME: "% 様",
      UNREAD: "未読",
      POINT_EXPIRE_TO: "ポイントの期限切れまで",
      AFTER_MONTH: "あと%1~%2ヶ月で期限切れ",
      AFTER_MONTH_2: "%1~%2ヶ月",
      AFTER_DAY: "あと%日以内で期限切れ",
      AFTER_DAY_2: "%日以内",
      UNTIL_DATE: "%失効",
      PLEASE_AUTHENTICATE: "アカウント登録のための認証を行ってください",
      PLEASE_INPUT: "下記項目を入力して認証を行ってください。",
      PHONE_NUMBER: "携帯電話番号",
      PHONE_NUMBER_EXAMPLE: "090 1234 5678",
      PHONE_NUMBER_REQUIRED: "携帯電話番号は必須です",
      PHONE_NUMBER_VALID: "有効な携帯電話番号を入力してください",
      PHONE_NUMBER_NOTE: "ハイフン(-)なしで入力してください",
      PIN_CODE: "認証コード",
      PIN_CODE_NOTE: "認証コードは乗務員アプリから発行し、SMS(メッセージ)で送られてきた4ケタの数字です",
      PIN_CODE_VALID: "有効な認証コードを入力してください",
      PIN_CODE_REQUIRED: "認証コードは必須です",
      AUTHENTICATE: "認証する",
      CONFIRM_CODE_VALID: "有効な確認コードを入力してください",
      CONFIRM_CODE_REQUIRED: "確認コードは必須です",
      ALREADY_HAVE_ACCOUNT: "すでにアカウントをお持ちの方",
      PLEASE_INPUT_EMAIL: "メールアドレスを入力してください",
      PLEASE_INPUT_EMAIL_EXAMPLE:
        "「GO乗務員ポータル」に登録するメールアドレスを入力してください。確認用のメールを送信します。",
      EMAIL: "メールアドレス",
      EMAIL_REQUIRED: "メールアドレスは必須です",
      EMAIL_INVALID: "メールアドレスの形式が正しくありません。",
      EMAIL_TOO_LONG: "254文字以下で入力してください。",
      CREW_NUMBER_VALID: "有効な乗務員番号を入力してください",
      CREW_NUMBER_REQUIRED: "乗務員番号は必須です",
      CREW_NUMBER_NOTE: "乗務員アプリで開局時に入力する番号を入力してください",
      AUTH_MAIL: "メールアドレス入力",
      CHANGE_EMAIL: "メールアドレス変更",
      CHANGE_PHONE_NUMBER: "携帯電話番号変更",
      CHANGE_PASSWORD: "パスワード変更",
      EMAIL_SENT: "メールアドレス登録確認のメールを送りました。メール内容をご確認ください。",
      EMAIL_SUCCESS: "メールアドレスを変更しました",
      PHONE_NUMBER_SUCCESS: "携帯電話番号を変更しました",
      PASSWORD_SUCCESS: "パスワードを変更しました",
      REGISTRATION_SUCCESS: "GO乗務員ポータルのアカウント登録が完了しました",
      FEATURE_PHONE: "フィーチャーフォン(スマートフォン・パソコン以外)の方は次へを押してください。",
      NEXT: "次へ進む",
      PLEASE_INPUT_CONFIRM_CODE_BY_SMS: "SMS(メッセージ)で受け取った確認コードを入力してください",
      CONFIRM_CODE_SENT_BY_SMS:
        "確認コードを携帯電話番号のSMS(メッセージ)に送信しました。\n確認コード4桁の数字を30分以内に入力してください。",
      PLEASE_INPUT_CONFIRM_CODE_BY_EMAIL: "メールで受け取った確認コードを入力してください",
      CONFIRM_CODE_SENT_BY_EMAIL: "確認コードをメールに送信しました。確認コード4桁の数字を30分以内に入力してください。",
      CONFIRM_CODE: "確認コード",
      CONFIRM_CODE_NOT_SENT: "確認コードが届かない、確認が通らない場合は再度確認コードを送信してください。",
      WHO_DO_NOT_RECEIVE_CONFIRM_CODE: "確認コードのメールが届きません",
      WHO_DO_NOT_RECEIVE_CONFIRM_CODE_OR_CHALLENGE_RESENDING:
        "メールアドレスを間違えた、確認が通らない、確認コードを再送信する方",
      WHAT_IS_CONFIRM_CODE: "確認コードとは何ですか?",
      TRY_AGAIN: "もう一度やり直す",
      CONTACT_US: "何度もこの画面が出る場合は、下記より問い合わせをお願いします。",
      CONTACT_US_WEB: "Webで問い合わせる",
      PLEASE_INPUT_PASSWORD: "新しいパスワードを設定してください",
      PASSWORD: "パスワード",
      PASSWORD_REQUIRED: "パスワードは必須です",
      PASSWORD_TOO_SHORT: "8文字以上で入力してください。",
      PASSWORD_TOO_LONG: "64文字以内で入力してください。",
      PASSWORD_CONFIRM_VALID: "パスワードが一致しません。",
      PASSWORD_CONFIRM_REQUIRED: "パスワード(確認用)は必須です。",
      PASSWORD_EXAMPLE: "8文字以上、英字・数字の組み合わせで設定してください",
      PASSWORD_CONFIRM: "パスワード(確認用)",
      SHOW_PASSWORD: "パスワードを表示する",
      REGISTER: "登録する",
      SET: "設定する",
      REGISTER_ACCOUNT: "アカウントを登録する",
      WHO_DO_NOT_HAVE_ACCOUNT: "まだアカウントがない方",
      ACCOUNT_REGISTERED: "GO乗務員ポータルのアカウント登録が完了しました",
      ACCOUNT_REGISTERED_EXAMPLE: "アカウント登録の完了メールが送信されていますのでご確認ください。",
      BOOKMARK_THIS_PAGE: "このページをブックマークしておくことをお勧めします。",
      AUTHENTICATION_FAILED: "認証ができません",
      AUTHENTICATION_FAILED_EXAMPLE: "認証コードを確認の上、もう一度やり直してください。",
      AUTHENTICATION_CODE_INVALIDATED: "認証コードが無効化されました",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE: "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE2: "認証コード発行から24時間語に新しい認証コードが発行できます。",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE3: "時間をおいてやり直してください。",
      AUTHENTICATION_CODE_INVALIDATED_EXAMPLE4: "認証コードを再発行する方法は、よくあるご質問をご確認ください。",
      FAQ: "よくある質問",
      AUTHENTICATION_CODE_EXPIRED: "認証コードの有効期限が切れています",
      AUTHENTICATION_CODE_EXPIRED_EXAMPLE: "乗務員アプリより、再度認証コードを送信してください。",
      EMAIL_ALREADY_REGISTERED: "このメールアドレスは、すでにGO乗務員ポータルのアカウントに登録されています",
      EMAIL_ALREADY_REGISTERED_EXAMPLE: "入力をご確認の上、もう一度やり直してください。",
      CONFIRM_FAILED: "確認ができません",
      CONFIRM_FAILED_EXAMPLE: "確認コードを確認の上、もう一度やり直してください。",
      CONFIRM_CODE_EXPIRED: "確認コードの有効期限が切れています",
      CONFIRM_CODE_EXPIRED_EXAMPLE: "再度、確認コードを送信してください。",
      ACCOUNT_REGISTRATION_FAILED: "アカウント登録ができません",
      ACCOUNT_REGISTRATION_FAILED_EXAMPLE: "お手数ですが下記より問い合わせをお願いします。",
      FORGOT_PASSWORD: "パスワードを忘れた方",
      RESET_PASSWORD: "パスワードを再設定する",
      TO_RESET_PASSWORD: "パスワード再設定へ",
      PASSWORD_NOTE: "半角英字・数字の両方を含む8文字以上で入力してください",
      PASSWORD_CONFIRM_NOTE: "確認のため、上で入力したパスワードと同じものを入力してください",
      WHO_CHANGED_PHONE_NUMBER: "携帯電話番号を変更した方",
      DO_CHANGE_EMAIL: "メールアドレスを変更する",
      WHO_CHANGED_EMAIL: "メールアドレスを変更した方",
      CANNOT_LOGIN: "ログインできない方",
      PASSWORD_INVALID: "半角英数字または（記号）で入力してください。",
      PASSWORD_INVALID_MIXED: "英字・数字の組み合わせで入力してください。",
      PHONE_OR_PASSWORD_INVALID: "携帯電話番号またはパスワードが正しくありません。",
      CONFIRM_CODE_MAX: "確認コードの文字数が最大値を超えています",
      CONFIRM_CODE_MIN: "確認コードの文字数が最小値を下回っています",
      ACCOUNT_LOCKED: "アカウントがロックされました",
      ACCOUNT_LOCKED_EXAMPLE_1: "規定回数ログイン失敗に失敗したため、アカウントがロックされました。",
      ACCOUNT_LOCKED_EXAMPLE_2: "パスワードを再設定し、ロックを解除してください。",
      WHAT_IS_ACCOUNT_LOCKED: "アカウントロックがかかりました",
      RESET_PASSWORD_EXAMPLE: "パスワードの再設定を行います",
      PLEASE_INPUT_EXAMPLE: "下記項目を入力してください。",
      PLEASE_INPUT_EXAMPLE_AUTH: "下記項目を入力して認証を行ってください。",
      PLEASE_SEND_PIN_CODE: "本人認証のため認証コードを送信してください",
      EMAIL_SENT_FOR_AUTHENTICATION: "本人認証のためメールを送信しました",
      EMAIL_SENT_FOR_CONFIRMATION: "メールアドレスの本人確認のためメールを送信しました",
      PLEASE_SEND_PIN_CODE_BY_EMAIL: "登録済みのメールアドレスに認証コードを送信します。",
      PLEASE_CHECK_YOUR_EMAIL: "メールを確認の上、メール文の案内にそって手続きを進めてください。",
      SMS_SENT_FOR_AUTHENTICATION: "本人認証のためSMS(メッセージ)を送信しました",
      PLEASE_SEND_PIN_CODE_BY_SMS: "登録済みの携帯電話番号のSMS(メッセージ)に認証コードを送信します。",
      PLEASE_CHECK_YOUR_SMS: "SMS(メッセージ)を確認の上、SMS文の案内にそって手続きを進めてください。",
      SEND_PIN_CODE: "認証コードを送信する",
      PLEASE_INPUT_PIN_CODE: "メールで受け取った認証コードを入力してください",
      PIN_CODE_SENT_EXAMPLE: "認証コードをメールに送信しました。認証コード4桁の数字を30分以内に入力してください。",
      PIN_CODE_NOT_SENT_EXAMPLE: "認証コードが届かない場合は、再度認証コードを送信してください。",
      SEND_PIN_CODE_AGAIN: "認証コードを再送信する",
      WHO_DO_NOT_RECEIVE_PIN_CODE: "認証コードのメールが届きません",
      WHO_DO_NOT_RECEIVE_PIN_CODE_OR_CHALLENGE_RESENDING: "認証が通らない、認証コードを再送信する方",
      WHAT_IS_PIN_CODE: "認証コードとは何ですか?",
      PLEASE_INPUT_NEW_EMAIL: "新しいメールアドレスを入力してください",
      SEND_CONFIRM_MAIL: "確認用のメールを送信します。",
      PLEASE_INPUT_NEW_PHONE_NUMBER: "新しい携帯電話番号を入力してください",
      SEND_CONFIRM_SMS: "確認用のSMS(メッセージ)を送信します。",
      PLEASE_REGISTER_NEW_PASSWORD: "新しいパスワードを登録してください",
      NEW_PASSWORD: "新しいパスワード",
      NEW_PASSWORD_CONFIRM: "新しいパスワード(確認用)",
      PASSWORD_RESET_SUCCESS: "パスワードの再設定が完了しました",
      PASSWORD_RESET_FAILED: "パスワードの再設定ができません",
      PASSWORD_RESET_FAILED_EXAMPLE: "お手数ですが下記よりお問い合わせをお願いします。",
      PIN_CODE_INVALIDATED: "認証コードが無効化されました",
      PIN_CODE_INVALIDATED_EXAMPLE_1: "認証コードの入力に規定回数失敗したため、コードが無効化されました。",
      PIN_CODE_INVALIDATED_EXAMPLE_2: "また、新しい認証コードは発行にもロックがかかりました。",
      PIN_CODE_INVALIDATED_EXAMPLE_3: "時間をおいてやり直してください。",
      POINT_EXCHANGE_APPLICATION: "ポイント交換申請",
      POINT_EXCHANGE_APPLICATION_HISTORY: "ポイント交換申請履歴",
      POINT_EARN_HISTORY: "ポイント獲得履歴",
      POINT_EXCHANGE_APPLICATION_HISTORY_EXAMPLE: "申請状況はポイント交換申請履歴より確認ください。",
      SEE_APPLICATION_HISTORY: "この申請履歴を確認する",
      POINT_EXCHANGE_APPLICATION_FAILED_1: "ポイント交換申請ができませんでした",
      POINT_EXCHANGE_APPLICATION_FAILED_2: "お手数ですが、もう一度はじめからやり直しをお願いします。",
      BACK_TO_TOP: "TOPに戻る",
      RECEIVE_METHOD: "受取方法",
      BANK_TRANSFER: "銀行振込",
      EXCHANGE_TO_CASH: "現金へ交換します。",
      BANK_TRANSFER_EXAMPLE_1: "銀行口座に振り込まれるまで1~5営業日かかります。",
      BANK_TRANSFER_SCHEDULE: "銀行口座の振込スケジュール",
      REMITTANCE_FEE: "送金手数料",
      ONCE_FREE: "1回分無料",
      EXCHANGEABLE_POINT: "交換可能ポイント",
      POINT_TO_YEN: "※1P=1円",
      POINT_EXPIRE_IN: "あと % 日以内で切れるポイント",
      EXCHANGE_AMOUNT: "交換金額",
      PLEASE_INPUT_EXCHANGEABLE_POINT: "交換可能ポイントの金額を入力してください",
      YEN: "円",
      CONFIRM_APPLICATION_CONTENTS: "申請内容を確認する",
      TOTAL_CONSUMPTION_POINT: "消費ポイント総額",
      BREAKDOWN: "内訳",
      OTHER: "営業協力金", // その他
      BANK_ACCOUNT: "振込先口座",
      BANK: "銀行",
      BRANCH_NAME: "支店名",
      ACCOUNT_TYPE: "口座種別",
      ACCOUNT_NUMBER: "口座番号",
      ACCOUNT_NAME: "口座名義",
      BANK_ACCOUNT_EXAMPLE_1: "振込先の口座情報は、GMO-PG送金サービスサイトにて登録ください。",
      BANK_ACCOUNT_EXAMPLE_2: "申請後、<strong>ユーザー番号</strong>が携帯電話番号のSMS(メッセージ)に届きます。",
      BANK_ACCOUNT_EXAMPLE_3: "ユーザー番号は口座情報の認証時に必要です。",
      APPLY_WITH_THIS_CONTENTS: "この内容で申請する",
      USER_NUMBER_SENT_TO_SNS: "SNS(メッセージ)にユーザー番号が届きます",
      BACK_AND_CHANGE_CONTENTS: "戻って内容を変更する",
      APPLICATION_NOT_COMPLETED_YET: "まだ申請は完了していません",
      REGISTER_BANK_ACCOUNT: "振込先口座を登録する",
      MOVE_TO_GMO_PG: "GMO-PG送金サービスサイトへ移動",
      USER_NUMBER_NOT_SENT: "ユーザー番号が届かない場合は、下記ボタンより再度ユーザー番号を送信してください。",
      SEND_USER_NUMBER_AGAIN: "ユーザー番号を再送信する",
      CANCEL_APPLICATION: "ポイント交換申請をやめる",
      POINT_EXCHANGE_APPLICATION_ACCEPTED: "ポイント交換申請を受け付けました",
      APPLICATION_ID: "申請ID",
      BANK_TRANSFER_EXAMPLE_2: "銀行振込の場合、銀行口座に振り込まれるまで1~5営業日かかります。",
      BANK_TRANSFER_EXAMPLE_3: "銀行振込は、ポイント交換申請の前にGMO-PGサイトにて振込先口座の登録が必要です。",
      POINT_HISTORY: "ポイント獲得履歴",
      POINT_TRANSFER_SUMMARY: "年間ポイント交換情報",
      TRANSFER_AMOUNT: "振込金額",
      TRANSFER_FEE: "振込手数料",
      TEN_PERCENT_TAX_RATE: "10%対象・消費税",
      FEE_BREAKDOWN: "手数料内訳",
      FEE_WITH_TAX: "送金手数料とエラー手数料には消費税10％が含まれています",
      TOTAL: "合計",
      FEE_TOTAL: "手数料合計",
      ANNUAL_TRANSFER_INFO: "年間ポイント交換情報について",
      PRIORITY_PASS: "優先パス",
      CANCEL_COMPENSATION: "キャンセル補填",
      AI_RESERVATION: "AI予約",
      PEAK_TIME: "繁忙時間帯",
      EXPIRED: "有効期限切れ",
      POINT_RETURN: "ポイント返却",
      PLEASE_INPUT_PIN_CODE_FROM_SMS: "SMS(メッセージ)で受け取った認証コードを入力してください",
      PIN_CODE_SENT_TO_SMS_EXMAPLE_1: "認証コードを携帯電話番号のSMS(メッセージ)に送信しました。",
      PIN_CODE_SENT_TO_SMS_EXMAPLE_2: "認証コード4桁の数字を30分以内に入力してください。",
      PLEASE_INPUT_PIN_CODE_FROM_EMAIL: "メールで受け取った認証コードを入力してください",
      PIN_CODE_SENT_TO_EMAIL_EXMAPLE:
        "認証コードをメールに送信しました。認証コード4桁の数字を30分以内に入力してください。",
      SKIP_PIN_CODE_INPUT_NEXT_TIME: "次回以降認証コードの入力を省略する",
      PIN_CODE_NOT_SENT_EXAMPLE_2: "認証コードが届かない場合は、下記ボタンより再度認証コードを送信してください。",
      POINT_EXCHANGE_APPLICATION_ERROR: "ポイント交換申請エラー",
      PIN_CODE_EXPIRED: "認証コードの有効期間が切れています",
      PIN_CODE_EXPIRED_EXAMPLE: "はじめからやり直してください。",
      START_OVER: "最初からやり直す",
      TO_POINT_EXCHANGE_APPLICATION: "ポイント交換申請へ",
      POINT_EXCHANGE_APPLICATION_HISTORY_LIST: "申請履歴一覧",
      REMITTANCE_PROCESSING: "手続き中",
      REMITTANCE_COMPLETED: "送金完了",
      REMITTANCE_ERROR: "エラー",
      REMITTANCE_CANCEL: "キャンセル",
      POINT_RETURN_EXAMPLE_1:
        "エラーが発生したためポイントが返却されました。エラー手数料(銀行振込) %Pが保有ポイントから差し引かれました。",
      RETURNED_POINT: "返却ポイント",
      RETURNED_DATE: "返却日",
      POINT_RETURN_EXAMPLE_2: "※返却時点で利用期限が切れているポイントは、返却後は利用できません。",
      POINT_RETURN_EXAMPLE_3: "エラーが発生したためポイントが返却されました。",
      ERROR_HANDLING_FEE: "エラー手数料について",
      PERIOD: "期間",
      FROM: "から",
      TO: "%まで",
      YEAR: "年",
      MONTH: "月",
      APPLICATION_STATUS: "申請状況",
      ALL_APPLICATIONS: "すべての申請",
      FILTER: "絞り込む",
      STATUS: "ステータス",
      ERROR_DETAIL: "エラー詳細",
      ACCOUNT_NUMBER_DIFFERENCE: "口座番号相違",
      PEAK_TIME_RETURN: "繁忙時間帯還元",
      CLOSE: "閉じる",
      WHO_DO_NOT_RECEIVE_SMS: "SMS(メッセージ)が届きません",
      WHO_DO_NOT_RECEIVE_EMAIL: "メールが届きません",
      VALID_HALF_NUM: "半角数字で入力してください。",
      VALID_NUM_LENGTH: "桁数が足りません。正しく入力できているか確認してください。",
      VALID_TOO_LONG: "%文字以内で入力してください。",
      VALID_PHONE_NUMBER_AREA_CODE: "携帯電話番号は070, 080, 090で始まる番号を入力してください。",
      REASON: "理由",
      CONFIRM_BEFORE_CONTACT: "お問い合わせ前に以下の項目を必ずお読みください。",
      CONFIRM_BEFORE_CONTACT_1: "お問い合わせの前に<Link>よくあるご質問</Link>もぜひご確認ください。",
      CONFIRM_BEFORE_CONTACT_2: "すべての項目を入力の上、お問い合わせ内容を送信してください。",
      CONFIRM_BEFORE_CREW_CONTACT: "アカウント情報については、必ず乗務員ご本人様からお問い合わせをお願いします。",
      CONFIRM_BEFORE_DRIVER_CONTACT: "アカウント情報については、必ずドライバーご本人様からお問い合わせをお願いします。",
      _REQUIRED: "は必須です",
      CONTACT_FORM_NOTICE: "異動・所属の営業所が統廃合された方はご確認ください",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_0:
        "異動や営業所統廃合により所属営業所が変わった方は以下のお手続きが必要となります。",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_1: "既存のGO乗務員ポータルのアカウントリセット*",
      CONTACT_COMMON_FORM_NOTICE_MESSAGE_2: "新しい乗務員番号でのGO乗務員ポータルへの登録",
      CONTACT_ACCOUNT_FORM_NOTICE_MESSAGE: "※1はお問い合わせ種別「アカウントリセット」を選択し、依頼してください。",
      CONTACT_FORM_NOTICE_MESSAGE:
        "※1は既存のGO乗務員ポータルにログイン後、お問い合わせフォームよりお問い合わせ種別「アカウントリセット」を選択し、依頼してください。",
      CONTACT_TYPE: "お問い合わせ種別",
      ACCOUNT_RESET_REQUEST: "アカウントリセット",
      TELEPHONE_NUMBER: "電話番号",
      TELEPHONE_NUMBER_NOTE: "ハイフン(-)なしで入力してください",
      NAME: "お名前",
      COMPANY_NAME: "所属する会社名",
      COMPANY_NAME_NOTE: "個人タクシーの方の場合は、組合名を入力してください",
      OFFICE_NAME: "所属する営業所名",
      CONTACT_CONTENTS: "お問い合わせ内容",
      CONTACT_CREW_NUMBER_NOTE:
        "・乗務員端末の開局/ログイン時に利用する番号を入力してください\n・乗務員以外の方は0000を入力してください",
      CONTACT_DRIVER_NUMBER_NOTE: "ドライバー以外の方は0000を入力してください",
      CONTACT_NOTICE_CREW_1: "GO乗務員ポイントに関する内容以外はお答えいたしかねます。あらかじめご了承ください。",
      CONTACT_NOTICE_DRIVER_1: "GOドライバーポイントに関する内容以外はお答えいたしかねます。あらかじめご了承ください。",
      CONTACT_NOTICE_2: "迷惑メール対策の設定をおこなわれている場合、当窓口からの返信が届かない場合がございます。",
      CONTACT_NOTICE_3:
        "当窓口からの返信が無い場合は、弊社ドメイン「@goinc.jp」からのメールを受信できるように設定し再度お問い合わせください。操作手順等の詳細はご利用の各キャリアへお問い合わせください。",
      CONTACT_NOTICE_4:
        "送信した内容はサービス向上のため、運営会社であるGO株式会社が確認します。また、内容によっては、タクシー事業者に開示する場合があります。",
      CONTACT_PRIVACY_POLICY_AGREE: "<Link>プライバシーポリシー</Link>に同意する",
      CONTACT_NOTICE_5: "<Link>アカウントリセットに伴う注意事項</Link>",
      CONTACT_RECEIVED: "お問い合わせを受付けました",
      CONTACT_RESPONSE_TIME_NOTICE: "弊社からの回答は、原則1〜2営業日以内に担当者よりメールにて返信致します。",
      UNFILLED_FIELDS_NOTICE: "入力されていない項目があります",
      PRIVACY_POLICY_UNAGREED: "プライバシーポリシーが同意されていません。",
      CONTACT_SUPPORT_HOURS_NOTICE:
        "対応時間:平日9:00〜17:00（土日、祝日、年末年始(12/28~1/5)を除く）\n※対応時間外にご連絡いただきました分につきましては、翌対応日9:00以降に順次ご返信いたします。",
      REQUIRES_OPERATION_WITH_DRIVER_APP: "『GOドライバー』アプリでの操作が必要です",
      OPEN_THE_DRIVER_APP_FROM_YOUR_SMART_PHONE: "スマートフォンから『GOドライバー』アプリを開いてください。",
      LOGIN_AS_DRIVER_NOTICE: "『GOドライバー』アプリと同じアカウントでログインしてください。",
      CHECK_EMAIL_NOTICE: "メールアドレスの注意事項をご確認ください",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_0:
        "以下に該当するメールアドレスの場合、GO乗務員ポータルからのメールが届きません。",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_1: "ドメインの受信拒否設定(迷惑メール設定)がされている",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_2: "特殊な形式のメールアドレスを利用している",
      INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_3: "詳細は以下のよくある質問を確認してください。",
      WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED: "確認コードのメールが届きません",
      ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL: "GO乗務員ポータルからのメールが届きません",
      IF_YOU_DO_NOT_RECEIVE_CONFIRMATION_EMAIL: "メールが届かない場合",
      CHECK_BELOW_FAQ:
        "ドメインの受信拒否設定(迷惑メール設定)がされている場合があります。詳細は以下のよくある質問を確認してください。",
      CONFIRMATION_EMAIL_NOT_ARRIVED: "メール登録エラー",
      DRIVER_SCORE: "お客様からの評価",
      WHAT_IS_DRIVER_SCORE: "お客様からの評価とは?",
      MAINTENANCE_PERIOD: "メンテナンス期間",
      MAINTENANCE_PERIOD_SUFFIX: "頃",
      WHAT_IS_COOKIE_DISABLED: "cookieの設定について",
    },
  },
};

const mergedResources = {
  en: {
    translation: {
      ...resources.en.translation,
      ...ErrorTitleResources.en,
      ...ErrorMessageResources.en,
      ...PointTransferResources.en,
      ...PointTransferHisotyResources.en,
      ...MappingRequestResources.en,
    },
  },
  jp: {
    translation: {
      ...resources.jp.translation,
      ...ErrorTitleResources.jp,
      ...ErrorMessageResources.jp,
      ...PointTransferResources.jp,
      ...PointTransferHisotyResources.jp,
      ...MappingRequestResources.jp,
    },
  },
};

i18n.use(initReactI18next).init({
  resources: mergedResources,
  lng: "jp",
  fallbackLng: "jp",
  interpolation: {
    escapeValue: false,
  },
});
